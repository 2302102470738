import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros servicios</h2>
          <p>
            Contamos con fabricación o reparación preventiva y de carácter urgente. 
          </p>
        </div>
        <div className="row">

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/fresado.jpg" className="img-responsive" alt="" />
              <h3>Fresado CNC</h3>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/torneado.jpg" className="img-responsive" alt="" />
              <h3>Torneado CNC</h3>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/maquinado.jpg" className="img-responsive" alt="" />
              <h3>Maquinado convencional</h3>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/cortehilo.jpg" className="img-responsive" alt="" />
              <h3>Corte por hilo EDM</h3>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/soldadura.jpg" className="img-responsive" alt="" />
              <h3>Soldadura</h3>
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc1">
              <img src="img/portfolio/termico.jpg" className="img-responsive" alt="" />
              <h3>Tratamiento térmico</h3>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
