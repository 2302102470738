import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>¿Quienes somos?</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>¿Por qué nosotros?</h3>
              <br />
              <div>
                <div>
                  <h4>Misión</h4>
                    <p>Continuar mejorando nuestros servicios, nuestros procesos y en general como compañía para cubrir las necesidades de la industria y poder brindar servicio de calidad a nuestros clientes, su satisfacción es nuestra prioridad.</p>
                </div>
                <br />
                <div>
                  <h4>Visión</h4>
                    <p>Establecernos a nivel regional como una de las mejores empresas en nuestro giro, satisfaciendo y superando la expectativa de nuestros clientes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
