import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Nuestros clientes</h2>
        </div>
        <div className="row">

        <div className="col-md-4">
            <div className="services-desc2">
              <img src="img/testimonials/tubacero.png" className="img-responsive" alt="" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc2">
              <img src="img/testimonials/parker.png" className="img-responsive" alt="" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="services-desc2">
              <img src="img/testimonials/deacero.png" className="img-responsive" alt="" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
