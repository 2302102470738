import React from "react";


export const Gallery = () => {

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Trabajo</h2>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src="img/portfolio/maquinacnc.png" className="img-responsive" alt="Maquinaria CNC" />
              <p>Contamos con maquinaria CNC: Fresadora HAAS VF-2 X 30” x Y 16” x Z 20”</p>
            </div>
            <div className="col-lg-6 col-md-12">
            <img src="img/portfolio/maquina_producto.png" className="img-responsive" alt="" />
              <p className="img-pdg">Aquí te mostramos una selección de piezas que hemos fabricado. Si estás interesado en obtener una cotización personalizada, no dudes en ponerte en contacto con nosotros.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img src="img/portfolio/machine.png" className="img-responsive" alt="Maquinaria" />
            </div>
            <div className="col-lg-6 col-md-12">
            <img src="img/portfolio/p2.jpg" className="img-responsive" alt="" />
            </div>
          </div>

        </div>
        
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/01-small.png" className="custom-img" alt="Imagen 1" />
              </div>
            </div>
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/02-small.png" className="custom-img custom-img-2" alt="Imagen 2" />
              </div>
            </div>
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/03-small.png" className="custom-img" alt="Imagen 3" />
              </div>
            </div>
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/04-small.png" className="custom-img custom-img-4" alt="Imagen 4" />
              </div>
            </div>
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/05-small.png" className="custom-img" alt="Imagen 5" />
              </div>
            </div>
            <div className="custom-col">
              <div className="custom-image-wrapper">
                <img src="img/portfolio/06-small.png" className="custom-img" alt="Imagen 6" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2>Servicio de retrabajo</h2>
              <p>Te brindamos el servicio de retrabajo de piezas fabricadas, retrabajar una pieza o componente es con frecuencia la mejor solución para hacer funcional un productos no conforme.</p>
            </div>
            <div className="col-lg-6 col-md-12">
              <h2>Servicios adicionales</h2>
              <li>Maquila de acabados: Pavonado, tropicalizado, galvanizado, niquelado y cromado.</li>
              <li>Maquila de tratamientos térmicos: Templado, nitrurado (QP, QPQ) y cementado.</li>
            </div>
         </div>

      </div>
    </div>
  );
};
